<template>
    <b-card>
        <div class="d-flex justify-content-between">
            <div class="flex">
                <b-button variant="primary" @click="onUpdateClicked" :disabled="visitor_id ? false : true">أدخل بيانات المستفيد</b-button>

                <b-button variant="primary" class="mx-2" :disabled="loadingNextBeneficiarys || !available"
                    @click="nextBeneficiarys({ id: id })">
                    <div v-if="loadingNextBeneficiarys" class="row align-items-center">
                        <b-spinner class="mx-50" small />
                        <span> المستفيد التالي </span>
                    </div>
                    <div v-else>
                        <feather-icon icon="UserIcon" class="mr-50" />
                        <span> المستفيد التالي </span>
                    </div>
                </b-button>
            </div>

            <div class="d-flex mt-50">
                <b-form-checkbox @change="changeStatus({ id: id })" :checked="available"
                  class="custom-control-primary" name="check-button" switch />
                <h6>متاح</h6>
            </div>
        </div>
        <hr />

        <b-row>
            <b-col>
                <b-card class="border shadow-sm h-75">
                    <h3>رقم البطاقة</h3>
                    <hr />
                    <h3 class="text-primary">{{ visitor_id }}</h3>
                </b-card>
            </b-col>
            <b-col>
                <b-card class="border h-75">
                    <h3>الخدمة</h3>
                    <hr />
                    <h3 class="text-primary">{{ service }}</h3>
                </b-card>
            </b-col>
            <b-col>
                <b-card class="border h-75">
                    <h3>زوار بالإنتظار</h3>
                    <hr />
                    <h3 class="text-primary">{{ count_waiting }}</h3>
                </b-card>
            </b-col>
            <b-col>
                <b-card class="border h-75">
                    <h3>رقم المكتب</h3>
                    <hr />
                    <h3 class="text-primary">{{ nomberOffice }}</h3>
                </b-card>
            </b-col>
        </b-row>

        <form-modal ref="estateModalOffice" :formSchema="estatesFormSchema" title=" بيانات المستفيد"
            v-model="activModel" :loading="loading" @confirm="onModalConfirmed">
        </form-modal>

    </b-card>
</template>

<script>

import { BCard, BButton, BRow, BCol, BFormCheckbox, BCardText, BSpinner } from 'bootstrap-vue';
import FormModal from "@/components/FormModal.vue"
import { mapActions, mapGetters } from 'vuex';
import LoaderBtn from "@/components/loader-btn.vue";

export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        FormModal,
        BFormCheckbox,
        BCardText, LoaderBtn, BSpinner
    },
    data() {
        return {
            
            activModel: false,
            estatesFormSchema: {
                full_name: {
                    component: "FormInput",
                    key: "title",
                    attrs: {
                        label: "الاسم الكامل",
                        rules: "required",
                    },
                },

                phone_number: {
                    component: "FormMask",
                    key: "phone_number",
                    attrs: {
                        options: "phone",
                        rules: "required",
                        label: "رقم الموبايل",
                        dir: "ltr",
                    },
                },
                cost: {
                    component: "FormMask",
                    key: "cost",
                    attrs: {
                        options: "number",
                        rules: "required",
                        label: "التكلفة",
                    },
                },
                notes: {
                    component: "FormTextarea",
                    key: "notes",
                    attrs: {
                        label: "الملاحظات",
                        rules: "required",
                    },
                }
            },
        }
    },
    methods: {
        ...mapActions("manageCenter/office", ["getDataOffice", "changeStatus", "nextBeneficiarys","checkStatus","emptyDataOffice"]),
        ...mapActions("manageCenter/manageVisitor", [
            "updateVisitor",
        ]),
        onAdd() {
            this.$refs.estateModalOffice.init({});
            this.activModel = true;
        },

        onModalConfirmed(form) {
            if (form.id) {
                this.updateVisitor({ id: form.id, fd: { ...form, cost: form.cost.replace(/\D/g, "") } }).then(() => {
                    this.activModel = false;
                    this.checkStatus();
                    this.emptyDataOffice();
                });
            }
        },
        onUpdateClicked(item) {
            var toUpdate = {
                id: 1,
                is_execute: 0,
                is_by_queue: 1,
                office_id:this.id,
            };
            this.$refs.estateModalOffice.init(toUpdate);
            this.activModel = true;
        },
    },
    computed: {
        ...mapGetters("manageCenter/office", ["nomberOffice", "id", "loading", "loadingNextBeneficiarys", "visitor_id", "service", "count_waiting","available"])
    },

    created() {
        this.getDataOffice();
    }
}
</script>